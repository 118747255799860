'use client'

import { cva } from 'class-variance-authority'
import { forwardRef, ReactNode } from 'react'
import { Avatar, AvatarSize } from '~/core/ui/Avatar'
import { Dot, IDotColorProps } from '~/core/ui/Dot'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import { Tooltip } from './Tooltip'

const inputChipVariants = cva(
  'flex items-center bg-gray-100 hover:bg-gray-300 focus:ring-1 focus:ring-primary-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-primary-700',
  {
    variants: {
      variant: {
        4: 'pl-3',
        3: 'pl-2.5',
        2: 'pl-2',
        1: 'pl-1.5',
        0.5: 'pl-0.5'
      },
      size: {
        lg: 'pr-3 h-7 py-0.5 rounded-2xl',
        md: 'pr-2.5 py-0.5 h-5 rounded-xl',
        sm: 'pr-2 h-5 rounded-xl'
      },
      dragged: {
        default: '',
        dragged: 'shadow'
      }
    },
    defaultVariants: {
      variant: 4,
      size: 'lg',
      dragged: 'default'
    }
  }
)

const inputChipLabelVariants = cva('font-medium', {
  variants: {
    size: {
      lg: 'text-base',
      md: 'text-sm',
      sm: 'text-sm'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
})

const inputChipMarginRightVariants = cva('', {
  variants: {
    size: {
      lg: 'mr-[6px]',
      md: 'mr-1',
      sm: 'mr-1'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
})

const inputChipMarginLeftVariants = cva('-mr-0.5', {
  variants: {
    size: {
      lg: 'ml-[6px]',
      md: 'ml-1',
      sm: 'ml-1'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
})

const getInputChipPaddingCondition = ({
  size = 'lg',
  type = 'default'
}: {
  size?: 'lg' | 'md' | 'sm'
  type?: 'default' | 'ava-leading' | 'dot-leading' | 'icon-leading'
}) => {
  if (size === 'lg' && type === 'default') return 4

  if (
    (size === 'md' && type === 'default') ||
    (size === 'lg' && ['dot-leading', 'icon-leading'].includes(type))
  ) {
    return 3
  }

  if (
    (size === 'sm' && type === 'default') ||
    (size === 'md' && ['dot-leading', 'icon-leading'].includes(type))
  ) {
    return 2
  }

  if (size === 'sm' && ['dot-leading', 'icon-leading'].includes(type)) return 1
  if (type === 'ava-leading') return 0.5

  return 4
}

const inputChipDefaultSizeAvatar: { [key: string]: AvatarSize } = {
  lg: 'sm',
  md: 'xs',
  sm: '2xs'
}
const inputChipDefaultSizeIcon: { [key: string]: number } = {
  lg: 16,
  md: 14,
  sm: 12
}

interface InputChipsProps {
  size?: 'lg' | 'md' | 'sm'
  type?: 'default' | 'ava-leading' | 'dot-leading' | 'icon-leading'
  removable?: boolean
  srcAvatar?: string
  altAvatar?: string
  colorAvatar?: string
  iconLeading?: LucideIconName
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  label?: string
  isDragged?: boolean
  color?: IDotColorProps
  buttonClassName?: string
  destructiveError?: boolean
  destructiveErrorText?: string | ReactNode
}

const InputChips = forwardRef<HTMLButtonElement, InputChipsProps>(
  (
    {
      size = 'lg',
      type = 'default',
      removable = false,
      srcAvatar,
      altAvatar,
      colorAvatar,
      iconLeading,
      onClick,
      label,
      isDragged,
      color,
      buttonClassName = 'w-full',
      destructiveError,
      destructiveErrorText
    },
    ref
  ) => {
    const renderTypeLeading = () => {
      if (type === 'ava-leading') {
        return (
          <span className={cn(inputChipMarginRightVariants({ size }))}>
            <Avatar
              color={colorAvatar}
              className="border-[1px] border-solid border-white dark:border-gray-500"
              src={srcAvatar}
              alt={altAvatar}
              size={inputChipDefaultSizeAvatar[size]}
            />
          </span>
        )
      }

      if (type === 'dot-leading') {
        return (
          <Dot
            color={color}
            size="lg"
            className={cn(inputChipMarginRightVariants({ size }))}
          />
        )
      }

      if (type === 'icon-leading') {
        return (
          <span className={cn(inputChipMarginRightVariants({ size }))}>
            <IconWrapper
              name={iconLeading}
              size={inputChipDefaultSizeIcon[size]}
            />
          </span>
        )
      }

      return ''
    }

    return (
      <Tooltip 
        content={destructiveErrorText} 
        classNameConfig={{
          content: destructiveError && destructiveErrorText ? '' : 'hidden'
        }}>
        <button
          type="button"
          ref={ref}
          className={cn(
            `${buttonClassName}`,
            inputChipVariants({
              variant: getInputChipPaddingCondition({
                size,
                type
              }),
              size,
              dragged: isDragged ? 'dragged' : 'default'
            })
          )}
        >
          {renderTypeLeading()}

          <span
            className={cn(
              'block truncate text-gray-700 dark:text-gray-100',
              inputChipLabelVariants({ size }),
              destructiveError ? 'text-red-500' : ''
            )}
          >
            {label}
          </span>

          {removable && (
            <span
              className={cn(inputChipMarginLeftVariants({ size }))}
              onClick={(event) => {
                if (onClick) {
                  onClick(event)
                }
              }}
            >
              <IconWrapper
                name="X"
                className={destructiveError ? 'text-red-500' : ''}
                size={inputChipDefaultSizeIcon[size]}
              />
            </span>
          )}
        </button>
      </Tooltip>
    )
  }
)

InputChips.displayName = 'InputChips'

export { InputChips }
export type { InputChipsProps }
