'use client'

import { forwardRef } from 'react'
import { Select, SelectProps } from '~/core/ui/Select'

const NativeSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    return <Select ref={ref} {...props} isMulti={false}/>
  }
)

NativeSelect.displayName = 'NativeSelect'

export { NativeSelect }
